<template>
  <div class="terms-of-use-page">
    <div class="section-content mx-auto" name="termly-embed" data-id="0fcfa501-cb44-46d6-8a6d-a980c3485daf" data-type="iframe"></div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUsePage',
  created() {
    setTimeout(() => {
      var js, tjs = document.getElementsByTagName('script')[0];
      js = document.createElement('script');
      js.id = 'termly-jssdk';
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    }, 500)
  },
}
</script>

<style lang="scss">
.terms-of-use-page {
  padding: 92px 16px;
  .section-content {
    max-width: 790px;
    width: 100%;
    h1 {
      font-family: "Cochin-LT", sans-serif;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #1D262D;
    }
    h2 {
      font-family: "Cochin-LT", sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #1D262D;
    }
    p {
      margin-left: 20%;
      font-size: 18px !important;
      line-height: 21px;
      @media screen and (max-width: 600px){
        margin-left: 0;
      }
    }
    .disable-margin {
      margin-left: 0;
      font-size: 24px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .terms-of-use-page {
    padding: 74px 16px;
    .section-header {
      font-size: 32px;
    }
    .section-content p {
      font-size: 22px;
    }
  }
}
</style>
