<template>
  <div class="terms-of-use-page">
    <div class="section-content mx-auto" name="termly-embed" data-id="acd54fc3-7e24-41fd-8180-4a93925ed415" data-type="iframe"></div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  created() {
    setTimeout(() => {
      var js, tjs = document.getElementsByTagName('script')[0];
      js = document.createElement('script');
      js.id = 'termly-jssdk';
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    }, 500)
  },
}
</script>

<style lang="scss">
.table {
  border-collapse: collapse;
  width: 100%;

  tr {
    td {
      border: 1px solid #000000;
      font-family: "Cochin-LT", sans-serif;
      vertical-align: middle;
      font-size: 36px;
      line-height: 1.12;
    }
  }
}

.terms-of-use-page {
  padding: 92px 16px;

  .section-content {
    max-width: 1068px;
    width: 100%;

    p {
      font-size: 36px;
      line-height: 1.12;
    }
  }
}

@media screen and (max-width: 767px) {
  .terms-of-use-page {
    padding: 74px 16px;

    .section-header {
      font-size: 32px;
    }

    .section-content p {
      font-size: 22px;
    }
  }
}
</style>
